<template>
  <div v-if="spin">
    <!-- Loading -->
    <div class="loading" :class="[showBackground ? 'transparent': 'bg']"><div><!-- <img src="@/assets/img/favicon.png"> --><div></div></div></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'Loading',
  props:{
    spin: {
      type: Boolean,
      default: false,
    },
    showBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isRouterResolved: (state) => state.isRouterResolved,
    }),
  },
  methods: {
  },
};
</script>
